import React, { useContext } from 'react'

import { Layout } from 'utils/Layout'
import {
  Cast,
  Creative,
  Gallery,
  Hero,
  ReviewSlider,
  TextBlock,
  TourDates,
  Signup,
} from 'components/Blocks'

import ModalContext from 'utils/ModalContext'

const Index = () => {
  //* Context is not used
  const { modalData, setModalData } = useContext(ModalContext)

  return (
    <Layout>
      <Hero />
      <ReviewSlider />
      {/* <Confetti /> */}
      <TextBlock id="about-the-show">
        <h3>
          The smash-hit West End production of{' '}
          <span className="red">ANNIE</span> has completed a{' '}
          <span className="red">2023</span> UK tour. Check back here for updates
          on future tours of the world’s favourite family musical!
        </h3>
        <p className="test">
          Set in 1930s New York during The Great Depression, brave young Annie
          is forced to live a life of misery at Miss Hannigan&rsquo;s orphanage.
          Her luck soon changes when she's chosen to spend a fairytale Christmas
          with famous billionaire, Oliver Warbucks. Meanwhile, spiteful Miss
          Hannigan has other ideas and hatches a plan to spoil Annie&rsquo;s
          search for her true family&hellip;
        </p>
        <p>
          With its Tony&reg; award-winning book and score, including the
          unforgettable songs It&rsquo;s The Hard-Knock Life, Easy Street, I
          Don&rsquo;t Need Anything But You and Tomorrow, you can bet your
          bottom dollar that you&rsquo;ll love it!
        </p>
      </TextBlock>
      {/* <TourDates/> */}
      {/* <Cast/> */}
      <Creative />
      <Gallery />
      <Signup />
    </Layout>
  )
}

export default Index
